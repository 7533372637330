import React, { MouseEvent, useEffect, useState } from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import useScrollToTop from '../../hooks/useScrollToTop';
import styles from './buttons.module.scss';

type ButtonScrollUpType = {
  showBtnFromPx?: number;
};

export const ButtonGoTop = (props: ButtonScrollUpType) => {
  const { showBtnFromPx = 500 } = props;
  const [active, setActive] = useState(false);
  const scrollTop = useScrollToTop();
  const [forceHide, setForceHide] = useState(false);
  const [initialLoadVisibility, setInitialLoadVisibility] = useState(false);

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  useEffect(() => {
    if (scrollTop > showBtnFromPx) {
      if (!active) {
        setActive(true);
        setInitialLoadVisibility(true);
      }
    } else {
      if (active) setActive(false);
    }
  }, [scrollTop, showBtnFromPx, active]);

  const scrollToTop = (e: MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    scrollTo({ top: 0, behavior: 'smooth' });
    setTimeout(() => {
      setActive(false);
      setForceHide(false);
    }, 1500);
  };

  if (forceHide) return null;

  return (
    <span
      className={`${styles['initial-button']} ${active ? styles['block'] : styles['non-block']} ${
        initialLoadVisibility ? '' : styles['hide-btn']
      }`}
      onClick={scrollToTop}
    >
      <img src={'/assets/icons/arrow-up.svg'} alt='go to top' />
    </span>
  );
};

export default ButtonGoTop;
