import React from 'react';
import styles from './footer.module.scss';
import { Col, Row } from 'react-bootstrap';
// @ts-ignore
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import CustomForm from '../forms/customform';
import Link from 'next/link';

export const legalLinks = [
  { label: 'Kontakt', url: '/de-DE/about/kontakt' },
  { label: 'FAQ', url: '/de-DE/about/faq' },
  { label: 'Über Uns', url: '/de-DE/about/ueber-uns' },
  { label: 'Presse', url: '/de-DE/about/presse' },
  { label: 'Partner werden', url: '/de-DE/about/partner-werden' },
  { label: 'Datenschutz', url: '/de-DE/about/datenschutz' },
  { label: 'AGB & Impressum', url: '/de-DE/about/impressum' },
  { label: 'Jobs', url: 'https://www.mybestbrands.de/karriere' }
];

const renderLegalLinks = () =>
  legalLinks.map((link) => (
    <Col md={6} className={styles.additionalLinks} key={link.url}>
      <div className='mb-2'>
        <Link href={link.url}>
          {link.label === 'Jobs' ? (
            <a target={'_blank'} rel='noreferrer'>
              {link.label}
            </a>
          ) : (
            <a>{link.label}</a>
          )}
        </Link>
      </div>
    </Col>
  ));

type MailChimpData = {
  subscribe: (formData: any) => void;
  status: string;
  message: string;
};

const Footer = () => {
  const getCurrentYear = () => new Date().getFullYear();
  const url =
    'https://mybestbrands.us4.list-manage.com/subscribe/post?u=9fd2e70bc2cb34057f48b0a88&amp;id=2ef07fc998';

  // @ts-ignore
  return (
    <>
      <span className={''}> </span>
      <Col md={6} className={`${styles.joinUsContainer}`}>
        <h3 className={`mt-4 mb-4 ${styles.Title}`}>Bleibe informiert</h3>

        <div className={`${styles.joinUsText}`}>
          Hole dir aktuelle Infos zum Thema Nachhaltigkeit aus den Bereichen Mode, Beauty und
          Lifestyle und erfahre mehr über neue Marken, Trends und vieles mehr!
        </div>

        <MailchimpSubscribe
          url={url}
          render={({ subscribe, status, message }: MailChimpData) => (
            <CustomForm
              status={status}
              message={message}
              onValidated={(formData: any) => subscribe(formData)}
            />
          )}
        />
        <div className={`mt-5 mb-md-5 mb-4 ${styles.joinUsInfo}`}>
          Mit der Anmeldung zum Newsletter bestätige ich, dass ich den Newsletter von FairlyFab
          erhalten möchte. Meine Einwilligung kann ich jederzeit mit Wirkung für die Zukunft
          widerrufen. Weitere Informationen dazu, wie wir Daten erheben und verarbeiten, findest du
          in unserer Datenschutzerklärung.
        </div>
      </Col>
      <Col md={6} className={`${styles.followUsContainer}`}>
        <h3 className={`mt-4 mb-4 pl-1 ${styles.follow} ${styles.Title}`}>Follow us</h3>
        <div className={`mt-4 mb-2 mr-2 pl-1 ${styles.socialIconsContainer}`}>
          <a href={`https://www.facebook.com/fairlyfabcom`} target={'_blank'} rel='noreferrer'>
            <span className={'pr-4'}>
              <img src='/assets/icons/facebook.svg' alt='facebook' />
            </span>
          </a>
          <a
            href={`https://www.instagram.com/fairlyfab_official/`}
            target={'_blank'}
            rel='noreferrer'
          >
            <span className={'pr-4'}>
              <img src='/assets/icons/instagram.svg' alt='instagram' />
            </span>
          </a>
        </div>

        <Row className={`pl-1 ${styles.containerLink}`}>{renderLegalLinks()}</Row>
        <div className={`mb-md-5 mb-3 pl-1 ${styles.rightsReserved}`}>
          © {getCurrentYear()} fairlyfab.
        </div>
      </Col>
    </>
  );
};

export default Footer;
