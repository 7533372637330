import React from 'react';
import styles from './customform.module.scss';
import { Button } from 'react-bootstrap';
// @ts-ignore
const CustomForm = ({ status, message, onValidated }) => {
  let email: HTMLInputElement | null;
  const submit = () =>
    email &&
    email.value.indexOf('@') > -1 &&
    onValidated({
      EMAIL: email.value
    });

  return (
    <div>
      {status === 'sending' && <div style={{ color: 'white' }}>sending...</div>}
      {status === 'error' && (
        <div style={{ color: 'red' }} dangerouslySetInnerHTML={{ __html: message }} />
      )}
      {status === 'success' && (
        <div style={{ color: 'white' }} dangerouslySetInnerHTML={{ __html: message }} />
      )}
      <input
        className={`mt-5 mb-5 ${styles.joinUsEmail}`}
        ref={(node) => (email = node)}
        type='email'
        placeholder='E-mail'
      />
      <br />
      <Button variant={`light`} className={`${styles.joinUsButton}`} onClick={submit}>
        Anmelden
      </Button>
    </div>
  );
};

export default CustomForm;
