import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import styles from './headerBanner.module.scss';
import { getCookie, setCookie } from '../../utils/cookies';

const HeaderBanner = ({ cookies }: { cookies: string }) => {
  const [showHeader, setShowHeader] = useState(!getCookie('hb_show', cookies));

  const closeHeader = () => {
    setCookie('hb_show', 'false', undefined, 30);
    setShowHeader(false);
  };

  return (
    <>
      {showHeader && (
        <Row className={`${styles.wrapper}`}>
          <Col className={'pr-0 pl-0 text-center'}>
            <span className={styles.text}>
              Deine erste Adresse für nachhaltige Mode & Lifestyle
            </span>
          </Col>
          <Col className={`d-xl-none pr-0 pl-0 ${styles.closeButton}`} onClick={closeHeader}>
            <span>
              <img src='/assets/icons/cancel-icon-white.svg' alt='close' />
            </span>
          </Col>
        </Row>
      )}
    </>
  );
};

export default HeaderBanner;
