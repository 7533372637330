import React, { ReactElement } from 'react';
import Head from 'next/head';
import { changeImgSrc } from '../../utils/utils';

interface Props {
  title: string;
  description: string;
  imgUrl: string;
  isBrandPost?: boolean;
  price?: any;
  uri: string;
  page?: string | string[] | number | undefined;
  hasCatProducts?: boolean;
}
const hostname = 'https://www.fairlyfab.com/de-DE';
const hostnameImg = 'https://www.fairlyfab.com';

const SeoMetadata = ({
  title,
  price,
  description,
  imgUrl,
  isBrandPost,
  uri,
  page,
  hasCatProducts
}: Props): ReactElement => {
  let img = '';
  if (imgUrl) {
    img = imgUrl.indexOf('wordpress') > -1 ? changeImgSrc(imgUrl) : imgUrl;
  }

  const link = uri === '/' ? '' : uri;
  const linkHasFilter = link.indexOf('?filter=') > -1;

  if (isBrandPost) {
    return (
      <Head>
        <link
          rel='canonical'
          href={
            page && page > 1 && !linkHasFilter
              ? `${hostname}${link.split('?')[0]}?page=${page}`
              : `${hostname}${link.split('?')[0]}`
          }
        />
        <meta name='description' content={description} />
        <meta name='twitter:card' content='summary' />
        <meta name='twitter:title' content={title} />
        <meta name='twitter:description' content={description} />
        <meta name='twitter:image' content={img} />
        <meta property='og:type' content='product' />
        <meta property='og:title' content={title} />
        <meta property='og:description' content={description} />
        <meta property='og:image' content={`${hostnameImg}${img}`} />
        <meta property='og:url' content={`${hostname}${link}`} />
        <meta property='product:price:amount' content={(0 | price).toFixed(2)} />
        <meta property='product:price:currency' content='EUR' />
        <meta property='og:locale' content='de_DE' />
      </Head>
    );
  } else if (hasCatProducts) {
    return (
      <Head>
        <link
          rel='canonical'
          href={
            page && page > 1 && !linkHasFilter
              ? `${hostname}${link.split('?')[0]}?page=${page}`
              : `${hostname}${link.split('?')[0]}`
          }
        />
        <meta name='description' content={description} />
        <meta name='twitter:card' content='summary' />
        <meta name='twitter:title' content={title} />
        <meta name='twitter:description' content={description} />
        <meta name='twitter:image' content={img} />
        <meta property='og:type' content='product' />
        <meta property='og:title' content={title} />
        <meta property='og:description' content={description} />
        <meta property='og:image' content={`${hostnameImg}${img}`} />
        <meta property='og:url' content={`${hostname}${link}`} />
        <meta property='og:locale' content='de_DE' />
      </Head>
    );
  }
  return (
    <Head>
      <link rel='canonical' href={`${hostname}${link}`} />
      <meta name='description' content={description} />
      <meta name='twitter:card' content='summary' />
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={description} />
      <meta name='twitter:image' content={img} />
      <meta property='og:type' content='website' />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:image' content={`${hostnameImg}${img}`} />
      <meta property='og:url' content={`${hostname}${link}`} />
      <meta property='og:locale' content='de_DE' />
    </Head>
  );
};
export default SeoMetadata;
